<template>
    <button
        :disabled="$attrs.disabled || loading"
        class="group relative inline-block px-1.5 md:px-2.5 py-4 md:py-5 bg-blueGray-900 disabled:bg-gray-500"
    >
        <div
            class="absolute top-0 left-0 transform -translate-y-1 -translate-x-1 w-full h-full group-hover:translate-y-0 group-hover:translate-x-0 transition duration-300 group-disabled:-translate-x-1 group-disabled:-translate-y-1 border"
            :class="`border-${border || 'white'}`"
        >
            <div
                class="flex h-full w-full items-center justify-center transition duration-300 group-disabled:bg-gray-400"
                :class="`bg-${color || 'blue-200'} text-${textColor || 'black'}`"
            >
                <span class="text-base font-black" :class="`text-${textColor}`">
                    <Loader v-if="loading" class="h-6 w-6 text-white"/>

                    <span v-else>{{ text }}</span>
                </span>
            </div>
        </div>
    </button>
</template>

<script>
import Loader from "@/Components/Svg/Loader.vue";

export default {
    components: {Loader},
    props: {
        text: {
            type: String,
            required: true
        },
        color: String,
        textColor: String,
        border: String,
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>
